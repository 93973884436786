// 新增、编辑合同和指派生产负责人时，用户选择的员工不能属于 合同管理员角色和生产院长角色
import { fetchDetail } from "@/api/setting/user";

export function checkUserRole(userId) {
    return new Promise((resolve, reject) => {
        fetchDetail({
            uuid: userId,
        }).then((res) => {
            if (res && Array.isArray(res.roles)) {

                let flag = false;
                res.roles.forEach(element => {
                    if (element.code === 'product_contractManager') {
                        flag = true;
                    }
                });

                if (flag) {
                    reject('选择人员无法作为生产负责人，请重选！');
                } else {
                    resolve();
                }
            } else {
                reject('接口返回数据格式不正确！')
            }
        }).catch(() => {
            reject('请求接口失败！');
        })
    })
}