<template>
  <div>
    <Pane :showRemarks="false" :bottom="0">
      <a-tabs v-model="active" :tabBarStyle="{
        margin: '0',
      }">
        <a-tab-pane key="info" tab="基本信息"> </a-tab-pane>
        <a-tab-pane key="subpackage" tab="分包"> </a-tab-pane>
      </a-tabs>
    </Pane>

    <div class="container">
      <div class="content" v-show="active === 'info'">
        <a-form :form="form" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" :colon="false" @submit="handleSubmit">
          <a-row>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="设计编号">
                <a-input v-decorator="[
                  'code',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]" />
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="合同名称">
                <a-input v-decorator="[
                  'name',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]" />
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="合同金额(万元)">
                <a-input-number :formatter="$inputNumberFormat" v-decorator="[
                  'contractAmount',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]" style="width: 100%" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24" v-if="!checkBranch(form.getFieldValue('productionUnitId'))">
              <a-form-item label="所属区域">
                <a-cascader placeholder="" v-decorator="[
                  'area',
                ]" :options="areaList" :fieldNames="{
  label: 'areaName',
  value: 'id',
  children: 'children',
}" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="生产部门">
                <a-select show-search :filter-option="$selectFilterOption" v-decorator="[
                  'oriProductionUnitId',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]" style="width: 100%" placeholder="">
                  <a-select-option v-for="item in productionDepartmentList" :key="item.id" :value="item.id">{{
                    item.uniqueName }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="责任部门">
                <a-select show-search :filter-option="$selectFilterOption" v-decorator="[
                  'productionUnitId',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]" style="width: 100%" placeholder="">
                  <a-select-option v-for="item in productionDepartmentList" :key="item.id" :value="item.id">{{
                    item.uniqueName }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="生产负责人">
                <div class="">
                  <!-- 生产负责人新增、编辑合同时，生产负责人字段不能改，就是自身 -->

                  <EmployeeSelector :disabled="isProduction" @change="(arr) => {
                    if (arr.length) {
                      charger = arr[0];
                      // 校验用户选择的人是否符合需求（不属于院长和合同管理员角色）
                      checkUserRole(arr[0].userId).catch((message) => {
                        $message.error(message);
                        charger = {};
                      });
                    } else {
                      charger = {};
                    }
                  }
                    " :value="charger.name ? [charger] : []">
                    <a-button block style="text-align: left" :disabled="isProduction">
                      <div v-if="charger.name">
                        {{ charger.name }}
                      </div>
                      <div v-else>&nbsp;</div>
                    </a-button>
                  </EmployeeSelector>
                </div>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="合同类型">
                <a-select v-decorator="[
                  'type',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]">
                  <a-select-option v-for="item in typeList" :key="item.value" :value="item.value"
                    :disabled="item.value === 'compensated' && !isContractAdmin">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <!-- <a-radio-group
                  v-decorator="[
                    'type',
                    { rules: [{ required: true, message: '请选择！' }] },
                  ]"
                >
                  <a-radio
                    v-for="item in typeList"
                    :key="item.id"
                    :value="item.value"
                    >{{ item.name }}</a-radio
                  >
                </a-radio-group> -->
              </a-form-item>
            </a-col>


            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="业主方">
                <CompanySelector @change="(company) => (selectedCompany = company)">
                  <a-button block style="margin-top: 4px; text-align: left" class="ellipsis">
                    {{ selectedCompany.name }}
                  </a-button>
                </CompanySelector>
              </a-form-item>
            </a-col>


            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item label="合同状态" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
                <a-radio-group v-decorator="[
                  'contractSignStatus',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]">
                  <!-- 生产负责人创建合同，只能创建‘跟踪中’和‘已中标’，所以其他两种禁用，合同管理员无限制 -->
                  <a-radio v-for="item in signStatusList" :disabled="(isProduction || isPresident) &&
                    (item.value === 'signed' || item.value === 'signing')
                    " :key="item.id" :value="item.value">{{ item.name }}</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item label="阶段" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
                <a-checkbox-group v-decorator="[
                  'phase',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]">
                  <div v-if="signStatus === 'unsigned'">
                    <a-checkbox v-for="item in showPhaseList" :key="item.id" :value="item.value">{{ item.name
                    }}</a-checkbox>
                  </div>

                  <a-row :gutter="[8, 8]" v-else>
                    <a-col :span="4" v-for="item in phaseList" :key="item.id">
                      <a-tooltip>
                        <template slot="title">
                          {{ item.name }}
                        </template>
                        <div class="text-hidden">
                          <a-checkbox :value="item.value">{{
                            item.name
                          }}</a-checkbox>
                        </div>
                      </a-tooltip>
                    </a-col>
                  </a-row>
                </a-checkbox-group>
              </a-form-item>
            </a-col>

            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
                <span slot="label" class="">附件</span>
                <div class="link-list">
                  <a-checkbox-group :value="selectedFileList" @change="onSelectFile">
                    <div class="link" v-for="item in fileList" :key="item.id">
                      <a-checkbox :value="item.id">
                        <a target="_blank" download :href="item.completePath">{{
                          item.name
                        }}</a>
                      </a-checkbox>
                    </div>
                  </a-checkbox-group>

                  <div class="control-bar">
                    <FileUpload @uploaded="uploaded">
                      <div class="control">
                        <a-icon type="upload" />
                      </div>
                    </FileUpload>

                    <div class="control" @click="deleteFile">
                      <a-icon type="delete" />
                    </div>
                  </div>
                </div>
              </a-form-item>
            </a-col>

            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item label="工程概况" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
                <a-textarea :auto-size="{ minRows: 3 }" v-decorator="['scale']" />
              </a-form-item>
            </a-col>


          </a-row>

          <div class="center">
            <a-space>
              <a-button type="primary" html-type="submit">下一步</a-button>
              <a-button @click="$close($route.path)">关闭</a-button>
            </a-space>
          </div>
        </a-form>
      </div>

      <div class="content" v-show="active === 'subpackage'">
        <SubPackage :necessaryList="necessaryList" :list="list" :otherList="otherList" @setList="setList" />

        <div class="center">
          <a-space>
            <a-button type="primary" @click="save" :loading="saveLoading">保存</a-button>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </div>
    </div>

    <CommentModal :visible="commentVisible" :title="commentTitle" @setVisible="setCommentVisible" @done="done" />
  </div>
</template>

<script>
import area from "@/mixins/area";
import organization from "@/mixins/organization";
import watermark from "@/mixins/watermark";
import { add } from "@/api/contract";
import { checkUserRole } from "./utils";
import { mapGetters, mapState } from "vuex";
import FileUpload from "@/components/file-upload";
import SubPackage from "./components/subpackage";
import CommentModal from "@/components/comment-modal";

import EmployeeSelector from "@/components/employee-selector";

import CompanySelector from "@/components/company-selector";

export default {
  name: "addContract",
  mixins: [area, organization, watermark],

  components: {
    FileUpload,
    SubPackage,
    CommentModal,
    EmployeeSelector,

    CompanySelector,
  },

  data() {
    return {
      active: "info",
      form: this.$form.createForm(this),
      signStatus: null,
      fileList: [],
      selectedFileList: [],

      writtenValue: {}, // 暂存用户填写的表单

      necessaryList: [], // 必要分包
      list: [], // 非必要
      otherList: [],

      charger: {}, // 生产负责人

      saveLoading: false,

      commentVisible: false, // 审批意见弹窗
      commentTitle: "", // 标题
      storageValue: {}, // 暂存的数据

      checkUserRole,

      selectedCompany: {}, // 业主方
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    typeList() {
      return this.findDataDict("contractType");
    },
    // typeFilterList() {
    //   return this.typeList.filter((item) => item.value !== "compensated");
    // },

    phaseList() {
      return this.findDataDict("phase");
    },
    signStatusList() {
      return this.findDataDict("contractSignStatus");
    },

    showPhaseList() {
      return this.phaseList.filter((item) => item.value === "FA");
    },

    // 提示语集合
    messageList() {
      return this.findDataDict("message");
    },

    unionList() {
      return this.findDataDict("unionType");
    },

    ...mapState("auth", ["user"]),
    ...mapGetters("auth", ["isProduction", "isPresident", "isContractAdmin"]), // 生产管理员角色、院长
  },

  mounted() {
    if (this.isProduction) {
      this.charger = {
        userId: this.user.uuid,
        name: this.user.userName,
      };
    }
  },

  methods: {
    // 检查选择的是否是分公司，如果是分公司，不需要所属区域
    checkBranch(id) {
      if (id) {
        const obj = this.productionDepartmentList.find(
          (item) => item.id === id
        );
        if (obj) {
          return obj.type && obj.type === "branch";
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    uploaded(list) {
      this.fileList = [...this.fileList, ...list];
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },

    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
    },

    handleSignStatusChange(e) {
      const { value } = e.target;
      this.signStatus = value;
      // 未签订时，选中方案
      if (value === "unsigned") {
        this.form.setFieldsValue({
          phase: ["FA"],
        });
      } else {
        this.form.setFieldsValue({
          phase: [],
        });
      }
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.writtenValue = values;

          this.active = "subpackage";
        }
      });
    },

    setList(payload) {
      const { list, isNecessary } = payload;
      if (isNecessary === 1) {
        this.necessaryList = list;
      } else if (isNecessary === 0) {
        this.list = list;
      } else if (isNecessary === 2) {
        this.otherList = list;
      }
    },

    // 审批意见弹窗设置
    setCommentVisible(visible) {
      this.commentVisible = visible;
    },

    save() {
      const values = this.writtenValue;

      const { code, name, contractAmount, area, type, phase } = values;

      if (!code || !name || !contractAmount || !type || !Array.isArray(phase)) {
        this.$message.error("请填写合同信息！");
        return;
      }

      let area1 = {};
      let area2 = {};
      let area3 = {};

      if (Array.isArray(area) && area.length > 0) {
        const [areaPId, areaId, areaId3] = area;
        area1 = this.rawAreaList.find((item) => item.id === areaPId) ?? {};
        area2 = this.rawAreaList.find((item) => item.id === areaId) ?? {};
        area3 = this.rawAreaList.find((item) => item.id === areaId3) ?? {};
      }

      const phaseStr = values.phase.join(",");

      // 如果当前登录用户是生产负责人角色，并且是选择的生产负责人，则提示他，保存会发起审批
      if (this.isProduction && this.user.uuid === this.charger.userId) {
        this.commentVisible = true;
        this.commentTitle = this.messageList.find(
          (item) => item.value === "editContract"
        ).name;
        this.storageValue = { area1, area2, area3, phaseStr, values };
      } else {
        this.control({ area1, area2, area3, phaseStr, values });
      }
    },

    done(comment) {
      this.control({
        ...this.storageValue,
        comment,
      });
    },

    control({ area1, area2, area3, phaseStr, values, comment }) {
      this.saveLoading = true;

      add({
        ...values,
        contractAmount: values.contractAmount * 10000,

        // 所属区域
        areaId: area1.id,
        areaName: area1.areaName,
        subAreaId: area2.id,
        subAreaName: area2.areaName,
        l3AreaId: area3.id,
        l3AreaName: area3.areaName,
        area: undefined,

        productionMasterId: this.charger.userId, // 生产负责人

        partyAid: this.selectedCompany.id,
        partyAname: this.selectedCompany.name,


        phase: phaseStr,

        // 附件
        attachments:
          this.fileList.length > 0
            ? this.fileList.map((item) => item.completePath).join(",")
            : undefined,

        // 分包
        subPackages: [
          ...this.necessaryList.map((item) => {
            return {
              ...item,
              isneeded: 1,
              planAmount: item.planAmount * 10000,
              attachments: item.fileList
                ? item.fileList.map((el) => el.completePath).join()
                : undefined,
              fileList: undefined,
            };
          }),
          ...this.list.map((item) => {
            return {
              ...item,
              isneeded: 0,
              planAmount: item.planAmount * 10000,
              attachments: item.fileList
                ? item.fileList.map((el) => el.completePath).join()
                : undefined,
              fileList: undefined,
            };
          }),
          ...this.otherList.map((item) => {
            return {
              ...item,
              isneeded: 2,
              planAmount: item.planAmount * 10000,
              attachments: item.fileList
                ? item.fileList.map((el) => el.completePath).join()
                : undefined,
              fileList: undefined,
            };
          }),
        ],

        comment,
      })
        .then(() => {
          this.form.resetFields();
          this.charger = {};
          this.selectedFileList = [];
          this.fileList = [];
          this.necessaryList = [];
          this.list = [];
          this.otherList = [];
        })
        .finally(() => {
          this.saveLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;

  .content {
    padding: 24px;
    margin: 0 auto;
  }
}

.center {
  margin-top: 80px;
}
</style>
